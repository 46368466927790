// src/components/TalksRedirect.jsx
import React, { useEffect } from 'react';

const ActivityRedirect = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const activityId = urlParams.get('id');
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Detectar el sistema operativo
    const isAndroid = /android/i.test(userAgent);
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

    if (activityId) {
      const appLink = `amiconus://talks/${activityId}`;
      const playStoreLink = 'https://play.google.com/store/apps/details?id=mx.amiconus.amiconus57cnsmm'; 
      const appStoreLink = 'https://apps.apple.com/mx/app/amiconus-57cnsmm/id6642659867?l=en-GB'; 

      // Intentar abrir el enlace profundo
      window.location.href = appLink;

      // Redirección de respaldo después de un breve tiempo
      setTimeout(() => {
        // Primero verifica si el usuario está en iOS o Android
        if (isIOS || isAndroid) {
          // Si es iOS, intentará abrir el App Store. Si es Android, intentará abrir Play Store.
          const storeLink = isIOS ? appStoreLink : playStoreLink;
          window.location.href = storeLink;
        } else {
          window.location.href = 'https://amiconus.mx'; // Redirige a la página principal si no se detecta el sistema
        }
      }, 1500); // 1.5 segundos de espera antes de redirigir a la tienda
    } else {
      window.location.href = 'https://amiconus.mx'; // Si no hay un ID válido, redirige a la página principal
    }
  }, []);

  return <p>Redirigiendo a la aplicación...</p>;
};

export default ActivityRedirect;