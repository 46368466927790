import React from "react";

export const PrivacyPolicy = () => {
  const pageContentStyle = {
    paddingTop: "100px",  // Espacio superior adicional
    paddingBottom: "100px", // Espacio inferior adicional
    textAlign: "justify", // Justificar el texto para una mejor legibilidad
    paddingLeft: "20px", // Espacio adicional a la izquierda
    paddingRight: "20px", // Espacio adicional a la derecha
  };

  const emailLinkStyle = {
    color: "#7B1FA2", // Color morado fuerte
    textDecoration: "none", // Elimina el subrayado por defecto
    fontWeight: "bold", // Hace el texto más visible
  };

  return (
    <div className="container-fluid" style={pageContentStyle}> {/* Aplicar la clase CSS y el estilo en línea */}
      <h2>Aviso de Privacidad</h2>
      <p>Última actualización: 16 de agosto de 2024</p>
      
      <h3>Responsable de la Protección de sus Datos Personales:</h3>
      <p>José Patricio Sánchez Hernández</p>
      
      <h3>Datos Personales que Recabamos:</h3>
      <p>Para brindarle nuestros servicios, recabamos los siguientes datos personales:</p>
      <ul>
        <li>Correo electrónico</li>
        <li>Contraseña</li>
        <li>Ubicación geográfica</li>
      </ul>
      
      <h3>Finalidades del Tratamiento de sus Datos Personales:</h3>
      <p>Los datos personales que recabamos serán utilizados para las siguientes finalidades necesarias:</p>
      <ul>
        <li>Registro y autenticación del usuario en la aplicación.</li>
        <li>Provisión de los servicios ofrecidos por la aplicación.</li>
        <li>Envío de un correo electrónico de bienvenida al usuario.</li>
        <li>Recuperación de la contraseña cuando el usuario lo necesite.</li>
        <li>Mostrar la ubicación del usuario en tiempo real en relación con los lugares que se muestran en el mapa de la aplicación.</li>
      </ul>
      
      <h3>Base Legal del Tratamiento de Datos:</h3>
      <p>
        El tratamiento de sus datos personales se basa en su consentimiento explícito al momento de registrarse en la aplicación, 
        así como en la necesidad de ejecutar los servicios que usted solicita a través de la misma.
      </p>
      
      <h3>Transferencia de Datos Personales:</h3>
      <p>
        Informamos que sus datos personales no serán compartidos con terceros sin su previo consentimiento, salvo que sea necesario 
        para el cumplimiento de las obligaciones legales. No compartimos su información personal con terceros para fines comerciales o publicitarios.
      </p>
      
      <h3>Seguridad de los Datos:</h3>
      <p>
        Hemos implementado medidas de seguridad administrativas, técnicas y físicas para proteger sus datos personales contra daño, 
        pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado.
      </p>
      
      <h3>Tiempo de Conservación de los Datos:</h3>
      <p>
        Los datos personales recabados serán conservados mientras el usuario no solicite su eliminación. Una vez que se reciba la 
        solicitud de eliminación, los datos serán eliminados de forma segura de nuestros sistemas.
      </p>
      
      <h3>Medios para Ejercer los Derechos ARCO:</h3>
      <p>
        Usted tiene derecho a acceder, rectificar y cancelar sus datos personales, así como a oponerse al tratamiento de los mismos o revocar 
        el consentimiento que para tal fin nos haya otorgado, a través de los siguientes medios:
      </p>
      <ul>
        <li>Correo electrónico: <a href="mailto:privacidad@amiconus.mx" style={emailLinkStyle}>privacidad@amiconus.mx</a></li>
      </ul>
      
      <h3>Derecho a Presentar una Queja ante la Autoridad:</h3>
      <p>
        Si usted considera que su derecho a la protección de sus datos personales ha sido vulnerado, tiene derecho a presentar una queja 
        ante la autoridad de protección de datos correspondiente.
      </p>
      
      <h3>Consentimiento para la Ubicación Geográfica:</h3>
      <p>
        La ubicación geográfica se recolecta y utiliza únicamente con su consentimiento explícito. Usted puede activar o desactivar el acceso 
        a su ubicación en cualquier momento a través de la configuración de la aplicación.
      </p>
      
      <h3>Cambios al Aviso de Privacidad:</h3>
      <p>
        Nos reservamos el derecho de realizar modificaciones o actualizaciones a este aviso de privacidad en cualquier momento, para la 
        atención de novedades legislativas, políticas internas o nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios. 
        Estas modificaciones estarán disponibles a través de la aplicación.
      </p>
      
      <h3>Contacto:</h3>
      <p>
        Si tiene alguna duda sobre este aviso de privacidad, puede contactarnos a través de:
      </p>
      <ul>
        <li>Correo electrónico: <a href="mailto:privacidad@amiconus.mx" style={emailLinkStyle}>privacidad@amiconus.mx</a></li>
      </ul>
      
      <p>Fecha de última actualización: 16 de agosto de 2024</p>
    </div>
  );
};

export default PrivacyPolicy;