import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
    <div className="intro card">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 intro-text">
          <h1 style={{ fontFamily: 'Futura Bk BT Book', fontWeight: 600}}>
            {props.data ? props.data.title : "Loading"}
          </h1>
            <p>{props.data ? props.data.paragraph : "Loading"}</p>
            <a href="#our-apps" className="btn btn-custom btn-lg page-scroll">
              Conocer más
            </a>
          </div>
          <div className="col-md-6 intro-image">
            <img src={"./img/mockup.png"} alt="Intro" />
          </div>
        </div>
      </div>
    </div>
  </header>
  );
};


