import React from "react";

export const TermsConditions = () => {
  const pageContentStyle = {
    paddingTop: "120px",  // Espacio superior adicional
    paddingBottom: "100px", // Espacio inferior adicional
    textAlign: "justify", // Justificar el texto para una mejor legibilidad
    paddingLeft: "20px", // Espacio adicional a la izquierda
    paddingRight: "20px", // Espacio adicional a la derecha
  };

  const emailLinkStyle = {
    color: "#7B1FA2", // Color morado fuerte
    textDecoration: "none", // Elimina el subrayado por defecto
    fontWeight: "bold", // Hace el texto más visible
  };

  return (
    <div className="container-fluid" style={pageContentStyle}>
      <h2>Términos y Condiciones de Uso</h2>
      <p>Última actualización: 22 de agosto de 2024</p>
      
      <h3>1. Aceptación de los Términos</h3>
      <p>
        Al descargar, instalar y utilizar la aplicación móvil AMICONUS 57CNSMM (de aquí en adelante “la aplicación”), usted acepta y se compromete a cumplir con estos Términos y Condiciones de Uso. Si no está de acuerdo con estos términos, por favor no utilice la aplicación.
      </p>
      
      <h3>2. Descripción del Servicio</h3>
      <p>La aplicación ofrece los siguientes servicios relacionados con el 57 Congreso Nacional de la Sociedad Matemática Mexicana (de aquí en adelante “el congreso”):</p>
      <ul>
        <li>Información detallada sobre las pláticas y actividades del congreso.</li>
        <li>Un calendario de pláticas y actividades del congreso.</li>
        <li>Información sobre los lugares donde se llevarán a cabo dichas pláticas y actividades.</li>
        <li>Enlaces a Google Maps para facilitar la ubicación de los lugares del evento y mostrar la ubicación en tiempo real del usuario.</li>
        <li>Enlaces a YouTube y a la página oficial de la Sociedad Matemática Mexicana para mayor información y contenido relacionado.</li>
        <li>Recomendaciones de museos, restaurantes y atracciones turísticas en Durango, Dgo., México.</li>
      </ul>
      
      <h3>3. Uso de la Aplicación</h3>
      <p>
        a. La aplicación es para uso personal y no comercial. <br />
        b. Usted no debe utilizar la aplicación para ningún propósito ilegal o no autorizado. <br />
        c. La información proporcionada en la aplicación está sujeta a cambios y actualizaciones.
      </p>
      
      <h3>4. Propiedad Intelectual</h3>
      <p>
        Todos los contenidos, incluyendo pero no limitándose a texto, gráficos, imágenes, logotipos, íconos y software, son propiedad de José Patricio Sánchez Hernández y están protegidos por leyes de derechos de autor y otras leyes de propiedad intelectual.
        Los logotipos o marcas de AMICONUS son marcas registradas pertenecientes a José Patricio Sánchez Hernández.
      </p>
      
      <h3>5. Limitación de Responsabilidad</h3>
      <p>
        a. La aplicación se proporciona “tal cual” y “según disponibilidad”. José Patricio Sánchez Hernández no garantiza que la aplicación será ininterrumpida o libre de errores. <br />
        b. José Patricio Sánchez Hernández no será responsable por cualquier daño directo, indirecto, incidental, especial, consecuente o ejemplar que resulte del uso o la imposibilidad de uso de la aplicación. <br />
        c. José Patricio Sánchez Hernández no asume responsabilidad por los servicios, calidad, precios o cualquier otro aspecto relacionado con los restaurantes, museos, atracciones turísticas u otros lugares comerciales o públicos recomendados en la aplicación. La inclusión de recomendaciones no implica respaldo o garantía alguna sobre dichos servicios. <br />
        d. La información sobre las pláticas y actividades del 57 Congreso Nacional de la Sociedad Matemática Mexicana, y si son llevadas a cabo o no, es responsabilidad exclusiva de la Sociedad Matemática Mexicana. José Patricio Sánchez Hernández no asume ninguna responsabilidad por la veracidad, precisión o cumplimiento de dicha información. <br />
        e. José Patricio Sánchez Hernández no asume ninguna responsabilidad por las actividades llevadas a cabo por terceros durante el 57 Congreso Nacional de la Sociedad Matemática Mexicana. <br />
        f. Los enlaces a Google Maps, YouTube y a la página oficial de la Sociedad Matemática Mexicana proporcionados en la aplicación son para conveniencia del usuario. José Patricio Sánchez Hernández no asume responsabilidad alguna por la calidad, disponibilidad o contenido de estos servicios externos. <br />
        g. Los descuentos, promociones y cupones ofrecidos por los restaurantes, atracciones o lugares que aparezcan en la aplicación son responsabilidad exclusiva del establecimiento correspondiente. José Patricio Sánchez Hernández no asume ninguna responsabilidad por estos descuentos, promociones o cupones, incluso si están mencionados en la aplicación. <br />
        h. José Patricio Sánchez Hernández no es responsable por la precisión o disponibilidad de la ubicación geográfica del usuario mostrada en la aplicación. La ubicación depende de factores externos, como la señal GPS, la conexión a Internet, y la configuración del dispositivo del usuario, entre otros, los cuales están fuera del control de José Patricio Sánchez Hernández.
      </p>
      
      <h3>6. Enlaces a Terceros</h3>
      <p>
        La aplicación puede contener enlaces a sitios web de terceros, incluyendo la página de la Sociedad Matemática Mexicana, sitios o páginas de restaurantes o lugares recomendados por la aplicación, YouTube y Google Maps. José Patricio Sánchez Hernández no tiene control sobre el contenido de estos sitios web y no asume ninguna responsabilidad por la calidad, disponibilidad o cualquier otro aspecto relacionado con el servicio proporcionado por estos terceros.
      </p>
      
      <h3>7. Privacidad</h3>
      <p>
        El uso de la aplicación también está regido por nuestro Aviso de Privacidad, que se incorpora a estos Términos y Condiciones de Uso por referencia. Al utilizar la aplicación, usted consiente la recopilación y el uso de su información como se describe en nuestro Aviso de Privacidad.
      </p>
      
      <h3>8. Modificaciones a los Términos</h3>
      <p>
        José Patricio Sánchez Hernández se reserva el derecho de modificar estos Términos y Condiciones de Uso en cualquier momento. Las modificaciones serán efectivas una vez publicadas en la aplicación. Es su responsabilidad revisar periódicamente los Términos y Condiciones de Uso actualizados, que se encuentran en la pantalla de usuario, en el apartado de privacidad y condiciones.
      </p>
      
      <h3>9. Ley Aplicable y Jurisdicción</h3>
      <p>
        Estos Términos y Condiciones de Uso se regirán e interpretarán de acuerdo con las leyes de México. Cualquier disputa que surja en relación con estos términos estará sujeta a la jurisdicción exclusiva de los tribunales de Durango, Durango, México.
      </p>
      
      <h3>10. Fotografías y Contenido Visual</h3>
      <p>
        Las fotografías de museos, restaurantes, lugares, atracciones turísticas, y demás contenidas en la aplicación pertenecen a los respectivos propietarios de estos establecimientos y son prestadas con autorización para su uso en la aplicación. Las fotografías del 57 Congreso Nacional de la Sociedad Matemática Mexicana también están incluidas bajo esta misma condición. Dichas fotografías no pueden ser utilizadas, reproducidas o distribuidas sin el consentimiento previo por escrito de los propietarios.
        Los logotipos y las imágenes de los patrocinadores del 57 Congreso Nacional de la Sociedad Matemática Mexicana son propiedad intelectual de dichos patrocinadores y están protegidos por leyes de propiedad intelectual. No pueden ser utilizados sin el consentimiento previo por escrito de los propietarios.
      </p>
      
      <h3>11. Contacto</h3>
      <p>
        Si tiene alguna pregunta o comentario acerca de estos Términos y Condiciones de Uso, por favor póngase en contacto con nosotros a través de <a href="mailto:terminosycondiciones@amiconus.mx" style={emailLinkStyle}>terminosycondiciones@amiconus.mx</a>.
      </p>
    </div>
  );
};

export default TermsConditions;