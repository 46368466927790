import React from "react";

export const Team = (props) => {
  const teamSectionStyles = {
    paddingTop: "30px", // Espaciado inicial para evitar solapamiento con la navbar
    paddingBottom: "10px", // Espaciado inferior para claridad visual
    textAlign: "center",
  };

  const sectionTitleStyles = {
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "30px",
  };

  const thumbnailStyles = {
    textAlign: "center",
    marginBottom: "30px",
  };

  const imageStyle = {
    width: "100%",
    height: "auto",
    borderRadius: "50%",
  };

  const captionStyle = {
    marginTop: "15px",
  };

  return (
    <div id="team" style={teamSectionStyles}>
      <div className="container">
        <div className="col-md-8 col-md-offset-2" style={sectionTitleStyles}>
          <h2>Equipo</h2>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 text-center">
                  <div className="thumbnail" style={thumbnailStyles}>
                    <img src={d.img} alt="Foto del equipo" className="team-img" style={imageStyle} />
                    <div className="caption" style={captionStyle}>
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                      {d.specialize && <p>{d.specialize}</p>}
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};

export default Team;