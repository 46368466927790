// NotFound.jsx
import React from "react";

const NotFound = () => {
  const containerStyle = {
    padding: "100px",
    textAlign: "center",
    color: "#000000", // Color negro para el texto principal
  };

  const linkStyle = {
    color: "#8000c7", // Color morado específico para el enlace
    textDecoration: "none", // Elimina el subrayado por defecto
    fontWeight: "bold", // Texto en negrita
    fontSize: "1.2em", // Aumenta ligeramente el tamaño del texto
  };

  return (
    <div style={containerStyle}>
      <h1>Error 404</h1>
      <p>Página no encontrada.</p>
      <a href="/" style={linkStyle}>Volver a la página principal</a> {/* Aplica el estilo aquí */}
    </div>
  );
};

export default NotFound;