import React from "react";

export const Image = ({ title, largeImage, smallImage }) => {
  const imageStyle = {
    maxWidth: "100%",
    height: "auto",
    borderRadius: "5px", // Agrega esquinas redondeadas si lo deseas
    margin: "0 auto", // Centrar imagenes
  };

  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        <a href={largeImage} title={title} data-lightbox-gallery="gallery1">
          <div className="hover-text">
            <h4>{title}</h4>
          </div>
          <img src={smallImage} className="img-responsive" alt={title} style={imageStyle} />
        </a>
      </div>
    </div>
  );
};

export default Image;