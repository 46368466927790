import React from "react";

export const Identity = (props) => {
  const identityStyles = {
    paddingTop: "20px", // Espaciado inicial para evitar solapamiento con la navbar
    paddingBottom: "10px", // Espaciado inferior para claridad visual
    textAlign: "center",
  };

  const logoStyles = {
    maxWidth: "60%", // Ajusta el tamaño del logo para que sea proporcional al contenido
    height: "auto",
    margin: "0 auto", // Centra el logo
    display: "block", // Asegura que el logo se muestre como un bloque
  };

  const textStyle = {
    textAlign: "justify", // Justifica el texto para que los párrafos se vean uniformes
    padding: "1rem", // Espaciado alrededor del texto
  };

  // Verificar si los datos están presentes
  if (!props.data) {
    return <div style={identityStyles}><h2>Loading...</h2></div>;
  }

  return (
    <div id="identity" style={identityStyles}>
      <div className="container">
        <div className="section-title">
          <h2>{props.data.title}</h2>
        </div>
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-xs-12 col-md-6">
            <img
              src={props.data.image}
              className="img-responsive"
              alt="Logotipo de amiconus"
              style={logoStyles}
            />
          </div>
          <div className="col-xs-12 col-md-6" style={textStyle}>
            <p>{props.data.paragraph1}</p>
            <p>{props.data.paragraph2}</p>
            <p>{props.data.paragraph3}</p>
            <p>
              Diseño de logotipo{" "}
              <a
                href={props.data.designer.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#337ab7", textDecoration: "underline" }}
              >
                @{props.data.designer.name}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Identity;