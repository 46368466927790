import React, { useState } from "react";
import { Link } from "react-router-dom";

export const OurApps = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const ourAppsStyles = {
    paddingTop: "20px", // Espaciado inicial para evitar solapamiento con la navbar
    paddingBottom: "10px", // Espaciado inferior para claridad visual
    textAlign: "center",
  };

  const subtitleStyles = {
    fontSize: "2.5rem", // Aumenta significativamente el tamaño de la fuente del subtítulo
    marginBottom: "30px", // Espacio inferior para separar del contenido
    fontWeight: "bold", // Negrita para mayor énfasis
    color: "#07be24", // Color específico para todo el subtítulo
  };

  const textStyle = {
    textAlign: "justify", // Justificar el texto para coherencia visual
    marginTop: "20px",
  };

  const buttonStyle = {
    display: "inline-block",
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: "#d9534f", // Color rojizo para el botón
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    textDecoration: "none", // Similar a los enlaces de política
  };

  // Verificar si los datos están presentes
  if (!props.data) {
    return <div style={ourAppsStyles}><h2>Loading...</h2></div>;
  }

  const handleImageClick = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div id="our-apps" style={ourAppsStyles}>
      <div className="container">
        <div className="section-title">
          <h2>{props.data.title}</h2>
          <h3 style={subtitleStyles}>{props.data.subtitle}</h3> {/* Aplicar color al subtítulo */}
        </div>

        {/* Imagen centrada y texto abajo */}
        <div style={{ textAlign: "center", marginBottom: "50px" }}>
          <img
            src={props.data.image}
            alt="App Mockup"
            style={{ maxWidth: "100%", height: "auto", cursor: "pointer", marginBottom: "20px" }}
            onClick={handleImageClick}
          />
          <p style={textStyle}>{props.data.paragraphDescription}</p>
        </div>

        {/* Restaurar la sección de características con los logos */}
        <div className="row portfolio-items">
          {props.data.features.map((feature, index) => (
            <div key={index} className="col-sm-6 col-md-3">
              <div className="feature-item text-center">
                <i className={feature.icon}></i>
                <h4>{feature.title}</h4>
                <p>{feature.text}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="section-footer" style={{ marginTop: "50px" }}>
          <p style={textStyle}>
            {props.data.credits}
          </p>
          <p>
            {props.data.policyText}{" "}
            <Link to={props.data.privacyLink} style={{ color: "#337ab7", textDecoration: "underline" }}>
              {props.data.privacyText}
            </Link>{" "}
            y nuestros{" "}
            <Link to={props.data.termsLink} style={{ color: "#337ab7", textDecoration: "underline" }}>
              {props.data.termsText}
            </Link>.
          </p>
          <a
            href={props.data.deleteDataLink}
            style={buttonStyle}
          >
            {props.data.deleteDataText}
          </a>
        </div>
      </div>

      {/* Modal para ampliar la imagen */}
      {isModalOpen && (
        <div className="modal" onClick={closeModal} style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000
        }}>
          <img
            src={props.data.image}
            alt="App Mockup Enlarged"
            style={{ maxWidth: "80%", maxHeight: "80%" }}
          />
        </div>
      )}
    </div>
  );
};

export default OurApps;