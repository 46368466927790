import React from "react";

export const About = (props) => {
  const aboutSectionStyles = {
    paddingTop: "20px", // Espaciado inicial para evitar solapamiento con la navbar
    paddingBottom: "10px", // Espaciado inferior para claridad visual
    textAlign: "center",
  };

  const textStyle = {
    textAlign: "justify", // Justifica el texto para los párrafos
  };

  const imageStyle = {
    maxWidth: "100%",
    height: "auto",
    marginBottom: "20px",
  };

  const columnStyle = {
    paddingLeft: "15px",
    paddingRight: "15px",
  };

  const listStyle = {
    listStyleType: "none",
    paddingLeft: "0",
  };

  const headingStyle = {
    fontWeight: "bold",
    marginBottom: "20px",
  };

  return (
    <div id="about" style={aboutSectionStyles}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 order-2 order-md-1" style={columnStyle}>
            <div className="about-text">
              <h2 style={headingStyle}>Nosotros</h2>
              <p style={textStyle}>{props.data ? props.data.paragraph : "loading..."}</p>
              <p style={textStyle}>{props.data ? props.data.paragraph2 : "loading..."}</p>
              <p style={textStyle}>{props.data ? props.data.paragraph3 : "loading..."}</p>
              <h3 style={headingStyle}>¿Por qué escogernos?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul style={listStyle}>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul style={listStyle}>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 order-1 order-md-2" style={columnStyle}>
            <img 
              src="img/about.png" 
              className="img-responsive about-image" 
              alt="Equipo de amiconus en acción" 
              style={imageStyle} 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;