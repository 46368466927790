import React from "react";
import { Image } from "./image";

// Definir los estilos para la galería
const galleryStyles = {
  paddingTop: "20px", // Espaciado inicial para evitar solapamiento con la navbar
  paddingBottom: "10px", // Espaciado inferior para claridad visual
  textAlign: "center", // Centrar el contenido
};

export const Gallery = () => {
  // Define el path base para las imágenes
  const basePath = "img/portfolio_app/";

  // Lista de nombres de archivos de imágenes
  const galleryImages = [
    "0_bienvenido.PNG",
    "1_home.PNG",
    "2_actividades.PNG",
    "3_category_talks.PNG",
    "4_talk1.PNG",
    "5_.PNG",
    "6_restaurants.PNG",
    "7_restaurant.PNG",
    "8_mis_actividades.PNG",
    "9_map1.PNG",
    "10_map2.PNG",
    "11_turism.PNG",
    "12_avisos.PNG",
    "13_user.PNG",
  ];

  return (
    <div id="portfolio" className="text-center" style={galleryStyles}>
      <div className="container">
        <div className="section-title">
          <h2>GalerÍa</h2>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {galleryImages.map((imageName, index) => (
              <div
                key={index}
                className="col-sm-6 col-md-4 col-lg-3" // 4 columnas por fila
                style={{ marginBottom: "30px", padding: "0 30px" }} // Espacio entre imágenes
              >
                <Image
                  title={`Imagen ${index + 1}`} // Título simple para cada imagen
                  largeImage={`${basePath}${imageName}`} // Usa la misma imagen para grande y pequeña
                  smallImage={`${basePath}${imageName}`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;