import React from "react";
import { Link, useNavigate } from "react-router-dom";

export const Navigation = () => {
  const navigate = useNavigate();

  const handleMenuItemClick = (event, path) => {
    event.preventDefault(); // Previene el comportamiento predeterminado del enlace

    const menuToggle = document.querySelector('.navbar-toggle');
    const menuCollapse = document.querySelector('#navbar-collapse');

    if (menuToggle && menuCollapse && menuCollapse.classList.contains('in')) {
      menuToggle.click();
    }

    // Si el camino actual incluye la ruta, realiza el desplazamiento; de lo contrario, cambia la ruta primero
    const currentPath = window.location.pathname + window.location.hash;
    if (currentPath === path) {
      // Solo desplazar si ya estamos en la misma ruta
      const targetId = path.split('#')[1];
      const element = targetId ? document.getElementById(targetId) : null;

      if (element) {
        const offsetTop = element.getBoundingClientRect().top + window.pageYOffset - 70; // Ajuste para la barra de navegación fija
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    } else {
      // Cambia la ruta y desplazar después
      navigate(path); // Navegar a la ruta especificada
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll a la parte superior
    }
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#navbar-collapse"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          {/* Enlace para volver a la página principal */}
          <Link to="/" className="navbar-brand page-scroll" onClick={(e) => handleMenuItemClick(e, "/#header")} style={{ fontFamily: 'Futura Bk BT Book', fontSize: '30px', color: '#808080', fontWeight: 600 }}>
            <img
              src="/img/icon.png"
              alt="Logo"
              style={{
                display: "inline-block",
                marginRight: "8px",
                verticalAlign: "top",
                maxHeight: "70px",
                marginTop: "-25px",
              }}
            />
            amiconus
          </Link>
        </div>
        <div className="collapse navbar-collapse" id="navbar-collapse">
          <ul className="nav navbar-nav navbar-right">
            <li>
              <Link to="/#about" className="page-scroll" onClick={(e) => handleMenuItemClick(e, "/#about")}>
                Nosotros
              </Link>
            </li>
            <li>
              <Link to="/#our-apps" className="page-scroll" onClick={(e) => handleMenuItemClick(e, "/#our-apps")}>
                Apps
              </Link>
            </li>
            <li>
              <Link to="/#portfolio" className="page-scroll" onClick={(e) => handleMenuItemClick(e, "/#portfolio")}>
                Galería
              </Link>
            </li>
            <li>
              <Link to="/#identity" className="page-scroll" onClick={(e) => handleMenuItemClick(e, "/#identity")}>
                Identidad
              </Link>
            </li>
            <li>
              <Link to="/#team" className="page-scroll" onClick={(e) => handleMenuItemClick(e, "/#team")}>
                Equipo
              </Link>
            </li>
            <li>
              <Link to="/#contact" className="page-scroll" onClick={(e) => handleMenuItemClick(e, "/#contact")}>
                Contacto
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;