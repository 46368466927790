// App.jsx
import React, { useEffect, useState } from "react";
import SmoothScroll from "smooth-scroll";
import { Team } from "./components/Team";
import { About } from "./components/about";
import { Contact } from "./components/contact";
import NotFound from "./components/NotFound"
// import { Features } from "./components/Features";
import { Gallery } from "./components/gallery";
import { Header } from "./components/header";
import { Navigation } from "./components/navigation";
import { Services } from "./components/services";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import { TermsConditions } from "./components/TermsConditions";
import { OurApps } from "./components/OurApps";
import { Identity } from "./components/Identity"; // Import the new Identity component
import TalksRedirect from "./components/TalksRedirect"; // Importar el componente de redirección actualizado
import JsonData from "./data/data.json";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const HEADER_HEIGHT = 70; // Ajusta la altura del header aquí

const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        // Ajusta el desplazamiento teniendo en cuenta la altura del header
        const offsetTop = element.offsetTop - HEADER_HEIGHT;
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth", // Desplazamiento suave
        });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return null;
};

const App = () => {
  const [landingPageData, setLandingPageData] = useState(null);

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  // Mostrar un mensaje de carga si los datos aún no están disponibles
  if (!landingPageData) {
    return <div className="loading-screen">LOADING...</div>;
  }

  return (
    <Router>
      <ScrollToTop />
      <Navigation />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header data={landingPageData.Header} />
              <About data={landingPageData.About} />
              <OurApps data={landingPageData.OurApps} />
              <Gallery data={landingPageData.Gallery} />
              {/* <Services data={landingPageData.Services} /> */}
              <Identity data={landingPageData.Identity} />
              <Team data={landingPageData.Team} />
              <Contact data={landingPageData.Contact} />
            </>
          }
        />
        <Route path="/privacidad" element={<PrivacyPolicy />} />
        <Route path="/terminosycondiciones" element={<TermsConditions />} />
        <Route path="/talks" element={<TalksRedirect />} /> {/* Ruta actualizada para redirección */}
        <Route path="*" element={<NotFound />} /> {/* Maneja cualquier otra ruta no especificada */}
      </Routes>
    </Router>
  );
};

export default App;