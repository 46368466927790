import React from "react";

export const Contact = (props) => {
  const contactSectionStyles = {
    paddingTop: "20px", // Espaciado inicial para evitar solapamiento con la navbar
    paddingBottom: "10px", // Espaciado inferior para claridad visual
  };

  const sectionTitleStyles = {
    marginBottom: "30px", // Espacio inferior para separar del contenido
    textAlign: "center", // Centrar el título para claridad
  };

  const socialStyles = {
    marginTop: "30px", // Espaciado superior para separar las redes sociales del contenido principal
  };

  const footerStyles = {
    padding: "20px 0", // Espaciado para el pie de página
    textAlign: "center",
  };

  const contactInfoStyles = {
    textAlign: "left", // Alinear a la izquierda los correos electrónicos
    paddingLeft: "15px", // Ajustar el espaciado para acercar las columnas
    paddingRight: "15px", // Ajustar el espaciado para acercar las columnas
  };

  const rightAlignTextStyles = {
    textAlign: "right", // Alinear texto a la derecha
    paddingLeft: "15px", // Ajustar el espaciado para acercar las columnas
    paddingRight: "15px", // Ajustar el espaciado para acercar las columnas
  };

  const logoStyles = {
    display: "block",
    margin: "0 auto",
    maxWidth: "70%", // Ajuste del tamaño del logo
  };

  return (
    <div>
      <div id="contact" style={contactSectionStyles}>
        <div className="container">
          {/* Título de contacto */}
          <div className="row">
            <div className="col-md-12">
              <div className="section-title" style={sectionTitleStyles}>
                <h2>Contacto</h2>
              </div>
            </div>
          </div>
          {/* Contenido con logo en el centro y columnas de correos electrónicos */}
          <div className="row d-flex justify-content-center align-items-center">
            {/* Primera columna de correos electrónicos */}
            <div className="col-md-4" style={contactInfoStyles}>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> General
                  </span>{" "}
                  <strong><a href="mailto:contacto@amiconus.mx">contacto@amiconus.mx</a></strong>
                </p>
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Organizadores de Congresos
                  </span>{" "}
                  <strong><a href="mailto:congresos@amiconus.mx">congresos@amiconus.mx</a></strong>
                </p>
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Restaurantes
                  </span>{" "}
                  <strong><a href="mailto:restaurantes@amiconus.mx">restaurantes@amiconus.mx</a></strong>
                </p>
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Turismo
                  </span>{" "}
                  <strong><a href="mailto:turismo@amiconus.mx">turismo@amiconus.mx</a></strong>
                </p>
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Soporte Técnico
                  </span>{" "}
                  <strong><a href="mailto:soporte@amiconus.mx">soporte@amiconus.mx</a></strong>
                </p>
              </div>
            </div>
            {/* Columna central para el logo */}
            <div className="col-md-4 text-center">
              <img
                src="img/logo_blanco.png" // Reemplaza con la ruta correcta de tu logo
                alt="Logo"
                style={logoStyles}
              />
            </div>
            {/* Segunda columna de correos electrónicos con texto a la derecha */}
            <div className="col-md-4" style={rightAlignTextStyles}>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Desarrollo y Tecnología
                  </span>{" "}
                  <strong><a href="mailto:desarrollo@amiconus.mx">desarrollo@amiconus.mx</a></strong>
                </p>
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Ventas y Marketing
                  </span>{" "}
                  <strong><a href="mailto:ventas@amiconus.mx">ventas@amiconus.mx</a></strong>
                </p>
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Relaciones Públicas
                  </span>{" "}
                  <strong><a href="mailto:prensa@amiconus.mx">prensa@amiconus.mx</a></strong>
                </p>
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Recursos Humanos
                  </span>{" "}
                  <strong><a href="mailto:recursoshumanos@amiconus.mx">recursoshumanos@amiconus.mx</a></strong>
                </p>
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Privacidad
                  </span>{" "}
                  <strong><a href="mailto:privacidad@amiconus.mx">privacidad@amiconus.mx</a></strong>
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-12" style={socialStyles}>
            <div className="row">
              <div className="social">
                <ul>
                  {/* <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="footer" style={footerStyles}>
        <div className="container text-center">
          <p>
            &copy; 2024 José Patricio Sánchez Hernández. Desarrollo por{" "}
            <a href="www.linkedin.com/in/ricardo-angel-fournier-delgado-">
              Ricardo Ángel Fournier
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;